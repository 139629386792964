/** ***********************************************************************
*
* Veracity Technology Consultants CONFIDENTIAL
* __________________
*
*  2019 Veracity Technology Consultants
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Veracity Technology Consultants and its suppliers,
* if any.  The intellectual and technical concepts contained
* herein are proprietary to Veracity Technology Consultants
* and its suppliers and may be covered by U.S. and Foreign Patents,
* patents in process, and are protected by trade secret or copyright law.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Veracity Technology Consultants.
*/
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Benefits from '../pages/benefits-and-entitlements';
import HookUpArticle from '../components/hookUpArticle';
import BenefitsTOC from '../components/tocs/benefits';
import JoiningTheMilitaryRoutes from './joining-the-military-routes';
import BnaRoutes from './bna-routes';
import DcfsaRoutes from './dcfsa-routes';
import RetirementRoutes from './retirement-routes';
import HcfsaRoutes from './hcfsa-routes';
import Oops404Page from '../pages/oops-404';

export default function BenefitsRoutes(props) {
    const root = props.match.path;
    const crumbs = `Knowing Your Benefits And Entitlements|${root}`;

    return (
      <Switch>
        <Route
          exact
          path={root}
          render={(routerProps) => <Benefits {...routerProps} crumbs={crumbs} toc={BenefitsTOC} />}
        />

        <Route
          exact
          path={`${root}/FSAQRCode`}
          render={(routerProps) => (
            <Redirect to={{
                pathname: root,
                hash: routerProps.location.hash,
            }}
            />
          )}
        />

        {[
            HookUpArticle(root, crumbs, BenefitsTOC, 'BAH.md', 'Benefits', 'Understanding Basic Allowance for Housing'),
            HookUpArticle(root, crumbs, BenefitsTOC, 'HouseHunting.md', 'Benefits', 'House Hunting? Explore the Veterans Affairs P Loan Benefit '),
            HookUpArticle(root, crumbs, BenefitsTOC, 'ZeroPercentStudentLoanInterest.md', 'Benefits', 'Zero Percent Student Loan Interest Relief'),
            HookUpArticle(root, crumbs, BenefitsTOC, 'TapResourcesPayEducation.md', 'Benefits', 'Maximize Your Education Benefits'),
            HookUpArticle(root, crumbs, BenefitsTOC, 'ServiceAidSocieties.md', 'Benefits', 'Seven Unexpected Ways Military Relief Societies Can Help Service Members'),
            HookUpArticle(root, crumbs, BenefitsTOC, 'HigherEdTraps.md', 'Benefits', 'Maximize Your Education Benefits: How to Avoid Higher Ed Scams'),
        ]}

        <Route
          exact
          path={`${root}/video/:video`}
          render={(routerProps) => <Benefits {...routerProps} crumbs={crumbs} toc={BenefitsTOC} isVideo />}
        />

        <Route
          path={`${root}/JoiningtheMilitary`}
          render={(routerProps) => <JoiningTheMilitaryRoutes {...routerProps} crumbs={crumbs} toc={BenefitsTOC} />}
        />

        <Route
          path={`${root}/BNA`}
          render={(routerProps) => <BnaRoutes {...routerProps} crumbs={crumbs} toc={BenefitsTOC} />}
        />

        <Route
          path={`${root}/DCFSA`}
          render={(routerProps) => <DcfsaRoutes {...routerProps} crumbs={crumbs} toc={BenefitsTOC} />}
        />

        <Route
          path={`${root}/Retirement`}
          render={(routerProps) => <RetirementRoutes {...routerProps} crumbs={crumbs} toc={BenefitsTOC} />}
        />

        <Route
          path={`${root}/HCFSA`}
          render={(routerProps) => <HcfsaRoutes {...routerProps} crumbs={crumbs} toc={BenefitsTOC} />}
        />

        <Route render={() => <Oops404Page />} />
      </Switch>
    );
}

